import React from "react";
import { useState, useEffect, useRef } from "react";
import { Box, TextField, Button, Typography, Autocomplete, IconButton, Avatar, Modal, Stack, CircularProgress, Select, MenuItem,InputLabel, FormControl, Switch } from "@mui/material";
import { colors } from "../../theme/Colors";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../utils/cropImage";
import * as Yup from "yup";
import MzErrorText from "../../components/ui/MzErrorText";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MzChip from "../../components/ui/MzChip";
import IconLink from "../../components/ui/IconLink";
import ConfirmationModal from "../../components/ui/ConfirmationModal";
import MyIconButton from "../../components/ui/MyIconButton";
import { ModalStyle } from "../../theme/ModalStyles";
import FileIcon from "../../imgs/file.svg";
import { useNavigate } from "react-router-dom";
import Person from "../../imgs/person.png";
import moment from "moment";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";

function CarteAbonneDetails() {
  const { user } = useAuthContext();
  const access_token = user.access_token;

  //is empty object
  function isObjEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  //allow "other" field add
  const [allowOtherField, setAllowOtherField] = useState();

  //location settings (get the id from onClick table)
  const { id } = useParams();
  // console.log("MY ID IS", id);

  //File config
  const FILE_SIZE = 5000 * 1024;
  const SUPPORTED_FORMATS_PROFILE_PIC = ["image/jpg", "image/jpeg", "image/png", "image/svg+xml", "image/webp"];

  //validation schema profile picture
  const validationSchemaProfilePicture = Yup.object({
    profile_picture: Yup.mixed()
      .required("Veuillez télécharger un fichier")
      .test("fileSize", "Fichier volumineux", (value) => value && value.size <= FILE_SIZE)
      .test("fileFormat", "Format du fichier invalide", (value) => value && SUPPORTED_FORMATS_PROFILE_PIC.includes(value.type)),
  });

  const [selectedLinkToDelete, setSelectedLinkToDelete] = useState();
  const [selectedDocToDelete, setSelectedDocToDelete] = useState();

  const [image, setImage] = useState();
  const [data, setData] = useState();

  const [links, setLinks] = useState();

  const [possibleLinksList, setPossibleLinksList] = useState();
  const [docs, setDocs] = useState();
  const [docToAddName, setDocToAddName] = useState();

  //trigger reload infos
  const [didUpdate, setDidUpdate] = useState(false);
  const [didUpdateLink, setDidUpdateLink] = useState(false);
  const [didSuspend, setDidSuspend] = useState(false);
  const [didActivate, setDidActivate] = useState(false);
  const [didDeleteLink, setDidDeleteLink] = useState(false);
  const [didAddLink, setDidAddLink] = useState(false);
  const [didDeleteDoc, setDidDeleteDoc] = useState(false);
  const [didAddDoc, setDidAddDoc] = useState(false);
  const [didUpdateProfilePicture, setDidUpdateProfilePicture] = useState(false);

  //modal config
  const [openAddLink, setOpenAddLink] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openSuspend, setOpenSuspend] = useState(false);
  const [openActive, setOpenActive] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openDeleteDoc, setOpenDeleteDoc] = useState(false);
  const [openAddDoc, setOpenAddDoc] = useState(false);
  const [openDeletePic, setOpenDeletePic] = useState(false);

  // suspend card modal
  const handleOpenSuspend = () => {
    // console.log(data?.id);
    if (data?.id === null || undefined) {
      toast.error("Aucune ligne séléctionnée");
      return;
    }
    setOpenSuspend(true);
  };
  const handleCloseSuspend = () => setOpenSuspend(false);

  //activate card modal
  const handleOpenActive = () => {
    if (data?.id === null || undefined) {
      toast.error("Aucune ligne séléctionnée");
      return;
    }
    setOpenActive(true);
  };
  const handleCloseActive = () => setOpenActive(false);

  //add link modal
  const handleOpenAddLink = () => {
    if (data?.id === null || undefined) {
      toast.error("Aucune ligne séléctionnée");
      return;
    }
    setOpenAddLink(true);
  };
  const handleCloseAddLink = () => setOpenAddLink(false);

  //delete link modal
  const handleOpenDelete = () => {
    setOpenDelete(true);
  };
  const handleCloseDelete = () => setOpenDelete(false);

  //delete document modal
  const handleOpenDeleteDoc = () => {
    setOpenDeleteDoc(true);
  };
  const handleCloseDeleteDoc = () => setOpenDeleteDoc(false);

  //update link modal
  const handleOpenUpdate = () => {
    setOpenUpdate(true);
  };
  const handleCloseUpdate = () => setOpenUpdate(false);

  //add doc modal
  const handleOpenAddDoc = () => {
    setOpenAddDoc(true);
  };
  const handleCloseAddDoc = () => setOpenAddDoc(false);

  const [change, setChange] = useState(false);

  //tab config
  const [inLinks, setInLinks] = useState(true);
  const [inDocuments, setInDocuments] = useState(false);
  const [currentVariantLink, setCurrentVariantLink] = useState("primary");
  const [currentVariantDocument, setCurrentVariantDocument] = useState("secondary");

  const handleButtonVariantChangeLink = () => {
    if ((currentVariantDocument === "secondary" && currentVariantLink === "primary") || (currentVariantDocument === "primary" && currentVariantLink === "secondary")) {
      setCurrentVariantLink("primary");
      setCurrentVariantDocument("secondary");
    }
  };
  const handleButtonVariantChangeDocument = () => {
    if ((currentVariantLink === "secondary" && currentVariantDocument === "primary") || (currentVariantLink === "primary" && currentVariantDocument === "secondary")) {
      setCurrentVariantDocument("primary");
      setCurrentVariantLink("secondary");
    }
  };

  //initial values link
  const initialValuesAddLink = {
    link_id: "",
    value: "",
    title: "",
  };

  //initial values doc
  const initialValuesAddDoc = {
    title: "",
    user_ids: [id] ?? "",
    document: "",
  };

  //validation schema link fields
  const validationSchemaAddLink = Yup.object({
    link_id: Yup.string().required("Ce champ est obligatoire"),
    value: Yup.string().required("Ce champ est obligatoire").url(),
    title: Yup.string().required("Ce champ est obligatoire"),
  });

  //validation schema add document fields
  const validationSchemaAddDoc = Yup.object({
    title: Yup.string().required("Ce champ est obligatoire"),
    document: Yup.mixed()
      .required("Veuillez selectionner un fichier")
      .test("fileSize", "Fichier volumineux", (value) => value && value.size <= FILE_SIZE),
  });

  function capitalizeName(name) {
    // Split the name into an array of words
    var words = name.toLowerCase().trim().split(/\s+/);

    // Capitalize the first letter of each word
    var capitalizedWords = words.map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });

    // Join the words back together into a string
    var capitalizedName = capitalizedWords.join(" ");

    // Return the capitalized name
    return capitalizedName;
  }

  // eliminate empty data
  function nonEmptyObject(obj) {
    let tempObj = obj;
    for (const propName in tempObj) {
      if ((typeof tempObj[propName] === "object" && Object.keys(tempObj[propName]).length === 0) || tempObj[propName] === null || tempObj[propName] === "") {
        delete tempObj[propName];
      }
    }
    return tempObj;
  }

  const navigate = useNavigate();
  const imageProfile = useRef();

  //on page load (load user details)
  useEffect(() => {
    // console.log("ID is", id);
    Api.get(Urls.GET_USER_ACCOUNT_DETAILS + `/${id}/account-details`, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
      .then((response) => {
        // console.log(response.data.user);
        setData(response.data.user);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error.response.status === 404) navigate("/not_found");
        // console.log(error);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  }, [didUpdate, didSuspend, didActivate, change, didUpdateProfilePicture]);

  //on page load (get DOCS)
  useEffect(() => {
    // console.log("ID is", id);
    Api.get(Urls.GET_USER_DOCUMENTS + `/${id}/documents`, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
      .then((response) => {
        // console.log("USER DOCS :", response.data.documents);
        setDocs(response.data.documents);
      })
      .catch((error) => {
        // console.log(error);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  }, [didDeleteDoc, didAddDoc]);

  //on page load (get LINKS)
  useEffect(() => {
    // console.log("ID is", id);
    Api.get(Urls.GET_USER_LINKS + `/${id}/userLinks`, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
      .then((response) => {
        // console.log("USER LINKS :", response.data.user_links);
        setLinks(response.data.user_links);
      })
      .catch((error) => {
        // console.log(error);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  }, [didDeleteLink, didAddLink, didUpdateLink]);

  //submit update user
  const onSubmitUpdateUser = (values) => {
    let realValues = nonEmptyObject(values);
    // console.log("UPDATE USER FUNCTION");
    // console.log("values", values);
    // console.log("access_token", access_token);
    Api.patch(
      Urls.UPDATE_USER_INFOS_ENTREPRISE + `${data?.id}`,
      {
        first_name: realValues.first_name,
        last_name: realValues.last_name,
        department: realValues.department,
        function: realValues.function,
        phone_number: realValues.phone_number,
        country_name: realValues.country_name,
        city_name: realValues.city_name,
        postal_code: realValues.postal_code,
        address: realValues.address,
        email: realValues.email,
        is_gift: realValues.is_gift,
        is_trained: realValues.is_trained
      },
      {
        headers: {
          "Content-type": "application/json",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((response) => {
        // console.log("RESPONSE UPDATE USER", response);

        if (response.status === 200) {
          // console.log(response);
          setDidUpdate(!didUpdate);
          toast.success("Informations du collaborateur changé avec succès");
          setTimeout(()=>{
            navigate('/admin/gestion-des-cartes')
          },1000)
        }
      })
      .catch((error) => {
        // console.log("RESPONSE", error.response.data.message);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };
  var configs = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgress(percentCompleted);
    },
  };
  //update profile picture
  const [onUploadImage, setOnUploadImage] = useState(false);

  const onSubmitUpdateProfilePicture = (values) => {
    setOnUploadImage(true);
    // console.log("values", values);
    // console.log("access_token", access_token);
    Api.post(
      Urls.UPDATE_PROFILE_PICTURE + `/${data?.id}/profilePicture`,
      {
        profile_picture: values.profile_picture,
      },
      configs
    )
      .then((response) => {
        // console.log("RESPONSE UPDATE USER", response);

        if (response.status === 200) {
          // console.log(response);
          toast.success(response.data.message);
        }
        setOnUploadImage(false);
        setDidUpdateProfilePicture(!didUpdateProfilePicture);
      })
      .catch((error) => {
        // console.log("RESPONSE", error.response.data.message);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
        setOnUploadImage(false);
      });
  };
  //update card status
  const updateCardStatus = (action) => {
    Api.patch(
      Urls.UPDATE_CARD_STATUS,
      {
        user_ids: [id],
        status_card: action,
      },
      {
        headers: {
          "Content-type": "application/json",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((response) => {
        // console.log("RESPONSE", response);

        if (response.status === 200) {
          // console.log(response);
          if (action === "ACTIVE") {
            toast.success("Carte activéee");
            setDidActivate(!didActivate);
          }
          if (action === "SUSPENDED") {
            toast.success("Carte suspendue");
            setDidSuspend(!didSuspend);
          }
        }
      })
      .catch((error) => {
        // console.log("RESPONSE", error.response.data.message);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };

  //get list possible links
  useEffect(() => {
    Api.get(Urls.GET_POSSIBLE_LINKS, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
      .then((response) => {
        // console.log("POSSIBLE LINKS :", response.data.links);
        setPossibleLinksList(response.data.links);
      })
      .catch((error) => {
        // console.log(error);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  }, []);

  //add link
  const addLink = (values) => {
    // console.log("values", values);
    // console.log("access_token", access_token);
    Api.post(
      Urls.CREATE_USER_LINK + `/${id}/userLinks`,
      {
        link_id: values.link_id,
        value: values.value,
        title: values.title,
      },
      {
        headers: {
          "Content-type": "application/json",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((response) => {
        // console.log("RESPONSE ADD LINK", response);

        if (response.status === 201) {
          // console.log(response);
          handleCloseAddLink();
          setDidAddLink(!didAddLink);
          toast.success("Lien ajouté avec succès");
        }
      })
      .catch((error) => {
        // console.log("RESPONSE", error.response.data.message);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };

  //upload progress
  const [progress, setProgress] = useState();

  //add doc
  const addDoc = (values) => {
    // console.log("values", values);
    // console.log("access_token", access_token);
    Api.post(
      Urls.ADD_USER_DOCUMENT,
      {
        title: values.title,
        user_ids: [id],
        document: values.document,
      },
      {
        headers: {
          "Content-type": "multipart/form-data",
          authorization: `Bearer ${access_token}`,
        },
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(percentCompleted);
        },
      }
    )
      .then((response) => {
        // console.log("RESPONSE ADD DOC", response);

        if (response.status === 201) {
          // console.log(response);
          handleCloseAddDoc();
          toast.success("Document ajouté avec succès");
          setDocToAddName("");
          setDidAddDoc(!didAddDoc);
          setProgress();
        }
      })
      .catch((error) => {
        // console.log("RESPONSE", error.response.data.message);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };

  //update link
  const updateLink = (values, id_link) => {
    // console.log("ABOUT TO UPDATE LINK WITH ID :", id_link);
    // console.log("VALUES TO UPDATE WITH :", values);
    Api.patch(
      Urls.DELETE_USER_LINK + `/${id_link}`,
      {
        link_id: values.link_id,
        value: values.value,
        title: values.title,
      },
      {
        headers: {
          "Content-type": "application/json",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((response) => {
        // console.log("RESPONSE UPDATE LINK", response);

        if (response.status === 200) {
          // console.log(response);
          handleCloseUpdate();
          setDidUpdateLink(!didUpdateLink);
          toast.success("Lien modifié avec succès");
        }
      })
      .catch((error) => {
        // console.log("RESPONSE", error.response.data.message);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };
  //delete user
  const deleteUser = () => {
    Api.delete(Urls.DELETE_USER, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      data: {
        user_ids: [id],
        passphrase: motdepasse,
      },
    })
      .then((res) => {
        toast.success("utilisateur supprimé");
        setOnDeletNext(false);
        setTimeout(() => {
          navigate("/admin/gestion-des-cartes");
        }, 500);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // console.log("RESPONSE", error.response.data.message);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };

  //delete link
  const deleteLink = (id_link) => {
    // console.log("access_token", access_token);
    Api.delete(
      Urls.DELETE_USER_LINK + `/${id_link}`,

      {
        headers: {
          "Content-type": "application/json",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((response) => {
        // console.log("RESPONSE DELETE LINK", response);

        if (response.status === 200) {
          // console.log(response);
          setDidDeleteLink(!didDeleteLink);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        // console.log("RESPONSE", error.response.data.message);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };

  //delete link
  const deleteDoc = (id_doc) => {
    // console.log("access_token", access_token);
    Api.delete(
      Urls.DELETE_USER_DOCUMENT + `/${id_doc}`,

      {
        headers: {
          "Content-type": "application/json",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((response) => {
        // console.log("RESPONSE DELETE DOC", response);

        if (response.status === 200) {
          // console.log(response);
          setDidDeleteDoc(!didDeleteDoc);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        // console.log("RESPONSE", error.response.data.message);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };

  //delete photo de profil
  const deleteProfilePic = () => {
    Api.delete(Urls.DELETE_PROFILE_PIC + id + "/profilePicture", {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        toast.success(res.data.message);
        setChange(!change);
        setImage(null);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };

  //crop image config
  const formikRef = useRef();
  const [format, setFormat] = useState("");
  const [onImage, setOnImage] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState();
  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    console.log(croppedArea, croppedAreaPixels);
    setCroppedAreaPixels(croppedAreaPixels);
  };
  const onCrop = async () => {
    const croppedImage = await getCroppedImg(image, croppedAreaPixels, format);
    setImage(URL.createObjectURL(croppedImage));
    var file = new File([croppedImage], `${Math.floor(Math.random() * 10000000000000000)}.${format.split("/")[1]}`, { lastModified: 1534584790000, type: [`${format}`] });
    if (file && croppedImage) {
      setOnImage(false);
      formikRef.current.values.profile_picture = file;
    }
  };
  const styleCropper = {
    containerStyle: {
      positon: "absolute",
      top: "50px",
      margin: "0 auto",
      height: "60%",
      width: "60%",
    },
  };
  //delete a user state
  const [ondelite, setOnDelete] = useState(false);
  const [ondeliteNext, setOnDeletNext] = useState(false);
  const [motdepasse, setMotDePasse] = useState("0zFgtYmLv9");

  const expirationDate = data?.expiration_date;
  const currentDate = new Date().toISOString().split('T')[0];
  const threeMonthsFromNow = new Date();
  threeMonthsFromNow.setMonth(threeMonthsFromNow.getMonth() + 3);
  const threeMonthsFromNowFormatted = threeMonthsFromNow.toISOString().split('T')[0];

  const getBackgroundColor = () => {
    if (expirationDate < currentDate) {
      return colors.bd_suspended_hover; // Expired
    } else if (expirationDate < threeMonthsFromNowFormatted) {
      return colors.mj_orange; // Less than 3 months to expire
    } else {
      return colors.mj_green; // More than 3 months to expire
    }
  };
    
  return (
    <Box>
      <Box>
        <Typography sx={{ marginBottom: "2rem", fontWeight: "500" }}>Détail du compte</Typography>
      </Box>

      {/* Header Section */}
      <Box sx={{ marginBottom: "2rem" }}>
        <Box
          display="flex"
          sx={{
            flexDirection: { md: "row", xs: "column" },
            gap: "2rem",
          }}
        >
          <Box>
            {/* formik 2 logo */}
            <Formik
              innerRef={formikRef}
              enableReinitialize={true}
              initialValues={{
                profile_picture: data?.url_profile_picture ?? "",
              }}
              validationSchema={validationSchemaProfilePicture}
              onSubmit={(values) => {
                onSubmitUpdateProfilePicture(values, access_token);
              }}
            >
              {({ values, setFieldValue, errors }) => (
                <Form>
                  <Box display="flex" flexDirection="row" gap="0.5rem">
                    <Box display="flex" flexDirection="column">
                      <Avatar
                        sx={{
                          position: "relative",
                          borderRadius: "20px",
                          width: "12rem",
                          height: "12rem",
                          border: "1px solid #C9C5D2",
                          marginBottom: "1rem",
                        }}
                        variant="rounded"
                      >
                        {image ? (
                          <img alt="profile" ref={imageProfile} src={image} style={{ height: "12rem", width: "12rem", objectFit: "fill", objectPosition: "50% 50%" }} />
                        ) : data?.url_profile_picture ? (
                          <img alt="profile" ref={imageProfile} src={data?.url_profile_picture} style={{ height: "12rem", width: "12rem", objectFit: "fill", objectPosition: "50% 50%" }} />
                        ) : (
                          <img alt="profile" src={Person} style={{ width: "12rem", height: "12rem" }} />
                        )}
                        <IconButton
                          sx={{
                            zIndex: "33",
                            position: "absolute",
                            right: "0.5rem",
                            bottom: "0.5rem",
                            bgcolor: `${colors.main_purple}`,
                            "&:hover": { bgcolor: `${colors.main_purple_hover}` },
                          }}
                          component="label"
                        >
                          <input
                            hidden
                            id="profile_picture"
                            name="profile_picture"
                            type="file"
                            onChange={(event) => {
                              setFieldValue("profile_picture", event.target.files[0] ? event.target.files[0] : null);
                              if (event.target.files[0]) {
                                setOnImage(true);
                                setImage(URL.createObjectURL(event.target.files[0]));
                                setFormat(event.target.files[0].type);
                              }
                            }}
                          />
                          <AddRoundedIcon htmlColor="white" />
                        </IconButton>
                      </Avatar>
                      <Modal
                        sx={{ backgroundColor: "#3f3f3f" }}
                        open={onImage}
                        onClose={() => {
                          setImage(null);
                          formikRef.current.values.profile_picture = null;
                          setOnImage(false);
                        }}
                      >
                        <Box height="100%" width="100%" margin="0 auto">
                          <Box sx={{ height: "80%", width: "80%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <Box height="100%" width="100%" display="flex" flexDirection="column">
                              <Cropper style={styleCropper} image={image} crop={crop} zoom={zoom} aspect={1 / 1} onCropChange={setCrop} onCropComplete={onCropComplete} onZoomChange={setZoom} />
                            </Box>
                          </Box>
                          <Box position="absolute" bottom="5%" left="0" right="0" gap="1rem" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                            <MzErrorText>{errors.profile_picture ? errors.profile_picture : null}</MzErrorText>
                            <Box width="fit-content" display="flex" flexDirection="row" gap="1rem" alignItems="center">
                              <Button disabled={errors?.profile_picture ? true : false} onClick={onCrop} variant="primary">
                                Confirmer
                              </Button>
                              <Button
                                variant="primary"
                                onClick={() => {
                                  setImage(null);
                                  formikRef.current.values.profile_picture = null;
                                  setOnImage(false);
                                }}
                              >
                                Annuler
                              </Button>
                            </Box>
                            <Box display="flex" gap="0.5rem" sx={{ flexDirection: { xs: "column", md: "row", lg: "row", sm: "row" }, alignItems: "center" }}>
                              <Typography color="white">Niveau de zoom : </Typography>
                              <input type="range" min={1} max={3} step={0.1} value={zoom} onInput={(e) => setZoom(e.target.value)} />
                            </Box>
                          </Box>
                        </Box>
                      </Modal>
                      <MzErrorText>{errors.profile_picture}</MzErrorText>
                      <Box display="flex" flexDirection="column" alignItems="center" gap="0.5rem" sx={{ margin: "0.5rem 0rem 1rem 0rem" }}>
                        <Box display="flex" gap="0.5rem" alignItems="center">
                          <Button variant="primary" type="submit">
                            Enregistrer la photo
                          </Button>
                          {data?.url_profile_picture && (
                            <IconButton
                              onClick={() => setOpenDeletePic(true)}
                              sx={{
                                zIndex: "33",
                                bgcolor: `${colors.bd_suspended}`,
                                "&:hover": { bgcolor: `${colors.bd_suspended_hover}` },
                              }}
                              component="label"
                            >
                              <DeleteRoundedIcon htmlColor="white" />
                            </IconButton>
                          )}
                        </Box>
                        <ConfirmationModal
                          title="Supprimer la photo de profile"
                          message="Êtes-vous sûr de bien vouloir supprimer la photo de profile  ?"
                          acceptLabel="oui"
                          refuseLabel="non"
                          open={openDeletePic}
                          onClose={() => setOpenDeletePic(false)}
                          acceptMethod={deleteProfilePic}
                          acceptMethodParams="SUSPENDED"
                        />
                        <Box>
                          {onUploadImage && (
                            <Box display="flex" alignItems="center" gap="1rem">
                              <CircularProgress size={30} />
                              <Typography width="20px" color={colors.main_purple}>
                                {progress ? progress + "%" : null}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
          <Box display="flex" flexDirection="column" gap="0.5rem">
            {data?.first_name && data?.last_name && <Typography variant="h3">{`${capitalizeName(data?.first_name)} ${data?.last_name.toUpperCase()}`}</Typography>}
            {data?.function && <Typography variant="secondary">{`${data?.function}`}</Typography>}
            <Box sx={{'display':'flex','alignItems':'center','gap':'.5rem'}}>
              {data?.status_card === "SUSPENDED" && <MzChip name="C_SUSPENDED" />}
              {data?.status_card === "ACTIVE" && <MzChip name="C_ACTIVE" />}
              {data?.status_card === "COMMANDED" && <MzChip name="C_COMMANDED" />}
              {data?.is_gift === true ? <MzChip name="C_GIFT" />: null}
              {data?.is_trained === true ? <MzChip name="C_TRAINED" />: null}
            </Box>
            {moment(data?.expiration_date).format() > moment(new Date()).format()
              ? data?.expiration_date && <Typography variant="secondary" fontSize={14} fontWeight={500} sx={{backgroundColor: getBackgroundColor(), color: 'white', paddingX:2, paddingY:1, borderRadius:20, width:'fit-content' }}>Carte valide jusqu'au {data?.expiration_date?.split("-")[2] + "/" + data?.expiration_date?.split("-")[1] + "/" + data?.expiration_date?.split("-")[0]}</Typography>
              : data?.expiration_date && <Typography variant="secondary" fontSize={14} fontWeight={500} sx={{backgroundColor: getBackgroundColor(), color: 'white', paddingX:2, paddingY:1, borderRadius:20, width:'fit-content' }}>Carte expirée le {data?.expiration_date?.split("-")[2] + "/" + data?.expiration_date?.split("-")[1] + "/" + data?.expiration_date?.split("-")[0]}</Typography>
            }
          </Box>
        </Box>
      </Box>
      {/* End Header Section */}

      {/* INFOS + LINK/DOCS Section */}
      <Box
        display="flex"
        sx={{
          flexDirection: { lg: "row", xs: "column" },
          gap: "2rem",
        }}
      >
        {/* Mes informations Section*/}

        <Formik
          enableReinitialize={true}
          initialValues={{
            email: data?.email ? '######' : "",
            first_name: data?.first_name ?? "",
            last_name: data?.last_name ?? "",
            department: data?.department ?? "",
            function: data?.function ?? "",
            phone_number: data?.phone_number ? '######' : "",
            country_name: data?.country_name ?? "",
            city_name: data?.city_name ?? "",
            postal_code: data?.postal_code ?? "",
            address: data?.address ?? "",
            status_card: data?.status_card ?? "",
            is_gift: data?.is_gift ?? false,
            is_trained: data?.is_trained ?? false,
          }}
          //validationSchema={validationSchemaUser}
          onSubmit={(values) => {
            const newValues = {
              email: values.email.includes('#') ? data.email : values.email,
              first_name: values.first_name,
              last_name: values.last_name,
              department: values.department,
              function: values.function,
              phone_number: values.phone_number.includes('#') ? data.phone_number : values.phone_number,
              country_name: values.country_name,
              city_name: values.city_name,
              postal_code: values.postal_code,
              address: values.address,
              status_card: values.status_card,
              is_gift: values.is_gift,
              is_trained: values.is_trained,
            };
            
            onSubmitUpdateUser(newValues, access_token); 
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Box maxWidth="500px">
                <Box>
                  <Typography mb="1rem">Informations du collaborateur</Typography>
                </Box>

                <Box
                  marginRight="1rem"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "1.3rem",
                  }}
                >
                  <Field  name="email" as={TextField} variant="outlined" label="Email*" size="small" />
                  <ErrorMessage name="email" component={MzErrorText} />
                </Box>
                <Box
                  marginRight="1rem"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "1.3rem",
                  }}
                >
                  <Field name="phone_number" as={TextField} variant="outlined" label="Téléphone professionel" size="small" />
                  <ErrorMessage name="phone_number" component={MzErrorText} />
                </Box>
                <Box
                  marginRight="1rem"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "1.3rem",
                  }}
                >
                  <Field name="department" as={TextField} variant="outlined" label="Département" size="small" />
                  <ErrorMessage name="department" component={MzErrorText} />
                </Box>
                <Box
                  marginRight="1rem"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "1.3rem",
                  }}
                >
                  <Field name="function" as={TextField} variant="outlined" label="Fonction" size="small" />
                  <ErrorMessage name="function" component={MzErrorText} />
                </Box>
                <Box
                  marginRight="1rem"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "1.3rem",
                  }}
                >
                  <Field name="country_name" as={TextField} variant="outlined" label="Pays" size="small" />
                  <ErrorMessage name="country_name" component={MzErrorText} />
                </Box>
                <Box
                  marginRight="1rem"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "1.3rem",
                  }}
                >
                  <Field name="city_name" as={TextField} variant="outlined" label="Ville" size="small" />
                  <ErrorMessage name="city_name" component={MzErrorText} />
                </Box>
                <Box
                  marginRight="1rem"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "1.3rem",
                  }}
                >
                  <Field name="postal_code" as={TextField} variant="outlined" label="Code postal" size="small" />
                  <ErrorMessage name="postal_code" component={MzErrorText} />
                </Box>
                <Box
                  marginRight="1rem"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "1.3rem",
                  }}
                >
                  <Field name="address" as={TextField} variant="outlined" label="Adresse" size="small" />
                  <ErrorMessage name="address" component={MzErrorText} />
                </Box>

                <Box
                  marginRight="1rem"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "1.3rem",
                    gap:'1rem'
                  }}
                >
                  <FormControl>
                    <Typography variant='h6' sx={{fontSize:'1rem'}} id="trained">is a Trained User</Typography>
                    <Switch
                      checked={values.is_trained}
                      onChange={(e) => {
                        setFieldValue("is_trained", e.target.checked);
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </FormControl>
                  <ErrorMessage name="trained" component={MzErrorText} />

                  <FormControl>
                    <Typography variant='h6' sx={{fontSize:'1rem'}} id="gift">is a Gift</Typography>
                    <Switch
                      checked={values.is_gift}
                      onChange={(e) => {
                        setFieldValue("is_gift", e.target.checked);
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </FormControl>
                  <ErrorMessage name="Gift" component={MzErrorText} />
                </Box>


              </Box>

              <Box sx={{ display: "flex", gap: "0.5rem", flexDirection: { md: "row", xs: "column" } }}>
                <Box>
                  <Button variant="primary" type="submit">
                    Enregistrer
                  </Button>
                </Box>
                <Box sx={{ display: "flex", flexDirection: { md: "row", xs: "column" }, gap: "0.5rem" }}>
                  <Box>
                    <Button disabled={data?.status_card === "ACTIVE"} sx={{ ":disabled": { backgroundColor: "gray", color: "white", border: "2px solid gray" } }} onClick={handleOpenActive} variant="primary">
                      Activer
                    </Button>
                  </Box>
                  <Box>
                    <Button disabled={data?.status_card === "SUSPENDED"} sx={{ ":disabled": { backgroundColor: "gray", color: "white", border: "2px solid gray" } }} onClick={handleOpenSuspend} variant="third">
                      Suspendre
                    </Button>
                  </Box>
                  <Box>
                    <Button onClick={() => setOnDelete(true)} sx={{ ":disabled": { backgroundColor: "gray", color: "white", border: "2px solid gray" } }} variant="third">
                      Supprimer
                    </Button>
                  </Box>
                </Box>
                <ToastContainer style={{ top: "5rem" }} position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
              </Box>
            </Form>
          )}
        </Formik>
        {/* End mes informations Section*/}
        <ConfirmationModal title="Supprimer une carte" message="Êtes-vous sûr de bien vouloir supprimer cette carte ?" acceptLabel="oui" refuseLabel="non" open={ondelite} onClose={() => setOnDelete(false)} acceptMethod={() => setOnDeletNext(true)} />
        <Modal open={ondeliteNext} onClose={() => setOnDeletNext(false)}>
          <Box sx={ModalStyle}>
            <Typography fontSize="1.5rem" fontWeight="600" mb>
              Supprimer une carte
            </Typography>
            <Typography mb="1rem">Entrez votre mot de passe</Typography>
            <Box marginBottom="1rem">
              <TextField type="password" value={motdepasse} onChange={(e) => setMotDePasse(e.target.value)} name="motdepasse" placeholder="Mot de passe" />
            </Box>
            <Box display="flex" flexDirection="row" gap="0.5rem">
              <Button
                variant="primary"
                onClick={() => {
                  deleteUser();
                }}
              >
                Supprimer
              </Button>
              <Button onClick={() => setOnDeletNext(false)} variant="third">
                Annuler
              </Button>
            </Box>
          </Box>
        </Modal>
        <ConfirmationModal title="Suspendre la carte" message="Êtes-vous sûr de bien vouloir suspendre cette carte  ?" acceptLabel="oui" refuseLabel="non" open={openSuspend} onClose={handleCloseSuspend} acceptMethod={updateCardStatus} acceptMethodParams="SUSPENDED" />
        <ConfirmationModal title="Activer la carte" message="Êtes-vous sûr de bien vouloir activer cette carte ?" acceptLabel="oui" refuseLabel="non" open={openActive} onClose={handleCloseActive} acceptMethod={updateCardStatus} acceptMethodParams="ACTIVE" />

        {/* Links Docs Section */}

        <Box sx={{ maxWidth: { xs: "500px" }, width: { md: "500px" } }}>
          <Box sx={{ display: "flex", gap: "0.5rem", marginBottom: "1rem" }}>
            <Button
              onClick={() => {
                setInLinks(true);
                setInDocuments(false);
                handleButtonVariantChangeLink();
              }}
              variant={currentVariantLink}
            >
              Liens
            </Button>
            <Button
              onClick={() => {
                setInDocuments(true);
                setInLinks(false);
                handleButtonVariantChangeDocument();
              }}
              variant={currentVariantDocument}
            >
              Documents
            </Button>
          </Box>

          {inLinks && (
            <Box width="100%" sx={{ backgroundColor: "#F9F6FF", borderRadius: "2rem", padding: "0.5rem" }}>
              {links?.map((l) => (
                <Box key={l.id} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                  <IconLink title={l.title} icon={l.link.url_logo} value={l.value} />
                  <Box>
                    <MyIconButton
                      sx={{ padding: "1rem" }}
                      name="edit"
                      onClick={() => {
                        setSelectedLinkToDelete(l);
                        if (l.link.slug === "other") {
                          setAllowOtherField(true);
                        } else {
                          setAllowOtherField(false);
                        }
                        handleOpenUpdate();
                      }}
                    />
                    <MyIconButton
                      sx={{ padding: "1rem" }}
                      name="delete"
                      onClick={() => {
                        setSelectedLinkToDelete(l);
                        handleOpenDelete();
                      }}
                    />
                  </Box>
                </Box>
              ))}

              <ConfirmationModal title="Supprimer un lien" message="Êtes-vous sûr de bien vouloir supprimer ce lien ?" acceptLabel="oui" refuseLabel="non" open={openDelete} onClose={handleCloseDelete} acceptMethod={deleteLink} acceptMethodParams={selectedLinkToDelete?.id} />

              <IconButton
                sx={{
                  margin: "0.5rem 1rem",
                  bgcolor: `${colors.main_purple}`,
                  "&:hover": { bgcolor: `${colors.main_purple_hover}` },
                }}
                onClick={handleOpenAddLink}
              >
                <AddRoundedIcon htmlColor="white" />
              </IconButton>

              {/* Modal add link */}
              <Modal open={openAddLink} onClose={handleCloseAddLink}>
                <Box sx={ModalStyle}>
                  <Typography fontSize="1.5rem" fontWeight="600" mb="2rem">
                    Ajouter un lien
                  </Typography>
                  {/* form add link */}
                  <Formik initialValues={initialValuesAddLink} validationSchema={validationSchemaAddLink} onSubmit={addLink}>
                    {({ values, setFieldValue }) => (
                      <Form>
                        <Box className="field" sx={{ marginBottom: "1rem" }}>
                          <Field
                            fullWidth
                            name="link_id"
                            component={Autocomplete}
                            options={possibleLinksList}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(pl) => (pl.slug === "other" ? "Autre" : pl.title)}
                            onChange={(event, value) => {
                              if (value === null) {
                                value = {};
                                setFieldValue("title", "");
                                setFieldValue("link_id", "");
                              }

                              setFieldValue("link_id", value ? value.id : "");
                              setFieldValue("title", value ? value.title : "");

                              if (value.slug === "other") {
                                setAllowOtherField(true);
                                setFieldValue("title", value.title);
                              } else {
                                setAllowOtherField(false);
                              }
                            }}
                            renderInput={(params) => <TextField {...params} name="autocomplete" label="Lien vers" />}
                            size="small"
                          />
                          <ErrorMessage name="link_id" component={MzErrorText} />
                        </Box>

                        {allowOtherField ? (
                          <Box className="field" sx={{ marginBottom: "1rem" }}>
                            <Field name="title" type="input" label="Titre" id="title" as={TextField} fullWidth size="small" />
                            <ErrorMessage name="title" component={MzErrorText} />
                          </Box>
                        ) : (
                          ""
                        )}

                        <Box className="field" sx={{ marginBottom: "1rem" }}>
                          <Field name="value" type="input" label="Url" id="value" as={TextField} fullWidth size="small" />
                          <ErrorMessage name="value" component={MzErrorText} />
                        </Box>

                        <Box display="flex" flexDirection="row" gap="0.5rem">
                          <Button variant="primary" type="submit">
                            Ajouter
                          </Button>
                          <Button onClick={handleCloseAddLink} variant="third">
                            Annuler
                          </Button>
                        </Box>
                        {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                      </Form>
                    )}
                  </Formik>
                  {/* end form add link */}
                </Box>
              </Modal>

              {/* modal update link */}
              <Modal open={openUpdate} onClose={handleCloseUpdate}>
                <Box sx={ModalStyle}>
                  <Typography fontSize="1.5rem" fontWeight="600" mb="2rem">
                    Mettre à jour un lien
                  </Typography>
                  {/* form update link */}
                  <Formik initialValues={{ link_id: selectedLinkToDelete?.link.id ?? "", value: selectedLinkToDelete?.value ?? "", title: selectedLinkToDelete?.title ?? "" }} validationSchema={validationSchemaAddLink}>
                    {({ values, setFieldValue, errors }) => (
                      <Form>
                        <Box className="field" sx={{ marginBottom: "1rem" }}>
                          <Field
                            fullWidth
                            name="link_id"
                            component={Autocomplete}
                            defaultValue={{ id: selectedLinkToDelete?.link.id ?? "", slug: selectedLinkToDelete?.link.slug, title: selectedLinkToDelete?.link.title, url_logo: selectedLinkToDelete?.link.url_logo }}
                            options={possibleLinksList}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(pl) => (pl.slug === "other" ? "Autre" : pl.title)}
                            onChange={(event, value) => {
                              if (value === null) {
                                value = { link_id: "" };
                                setFieldValue("title", "");
                                setFieldValue("link_id", "");
                              }

                              setFieldValue("link_id", value ? value.id : "");
                              setFieldValue("title", value ? value.title : "");

                              if (value.slug === "other") {
                                setAllowOtherField(true);
                                setFieldValue("title", value ? "Site web" : "");
                              } else {
                                setAllowOtherField(false);
                              }
                            }}
                            renderInput={(params) => <TextField {...params} name="autocomplete" label="Lien vers" />}
                            size="small"
                          />
                          <ErrorMessage name="link_id" component={MzErrorText} />
                        </Box>

                        {allowOtherField ? (
                          <Box className="field" sx={{ marginBottom: "1rem" }}>
                            <Field name="title" type="input" label="Titre" id="title" as={TextField} fullWidth size="small" />
                            <ErrorMessage name="title" component={MzErrorText} />
                          </Box>
                        ) : (
                          ""
                        )}

                        <Box className="field" sx={{ marginBottom: "1rem" }}>
                          <Field name="value" type="input" label="Url" id="value" as={TextField} fullWidth size="small" />
                          <ErrorMessage name="value" component={MzErrorText} />
                        </Box>

                        <Box display="flex" flexDirection="row" gap="0.5rem">
                          <Button
                            variant="primary"
                            onClick={() => {
                              // console.log("SELECTED LINK TO UPDATE:", selectedLinkToDelete?.id);
                              // console.log("ERRORS :::", errors);
                              if (isObjEmpty(errors)) {
                                updateLink(values, selectedLinkToDelete?.id);
                              }
                            }}
                          >
                            Mettre à jour
                          </Button>
                          <Button onClick={handleCloseUpdate} variant="third">
                            Annuler
                          </Button>
                        </Box>
                        {/* <pre>{JSON.stringify(values, null, 2)}</pre>
                        <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                      </Form>
                    )}
                  </Formik>
                  {/* end form update link */}
                </Box>
              </Modal>
              {/* end modal update link  */}
            </Box>
          )}

          {inDocuments && (
            <Box width="100%" sx={{ backgroundColor: "#F9F6FF", borderRadius: "2rem", padding: "0.5rem" }}>
              {docs?.map((d) => (
                <Box key={d.id} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                  <IconLink title={d.title} value={d.url_file} />
                  <Box>
                    <MyIconButton
                      onClick={() => {
                        setSelectedDocToDelete(d);
                        handleOpenDeleteDoc();
                      }}
                      sx={{ padding: "1rem" }}
                      name="delete"
                    />
                  </Box>
                </Box>
              ))}
              <IconButton
                sx={{
                  margin: "0.5rem 1rem",
                  bgcolor: `${colors.main_purple}`,
                  "&:hover": { bgcolor: `${colors.main_purple_hover}` },
                }}
                onClick={() => {
                  handleOpenAddDoc();
                }}
              >
                <AddRoundedIcon htmlColor="white" />
              </IconButton>
              {/* Modal add DOC */}
              <Modal open={openAddDoc} onClose={handleCloseAddDoc}>
                <Box sx={ModalStyle}>
                  <Typography fontSize="1.5rem" fontWeight="600" mb="2rem">
                    Ajouter un document
                  </Typography>
                  {/* form add doc */}
                  <Formik initialValues={initialValuesAddDoc} validationSchema={validationSchemaAddDoc} onSubmit={addDoc}>
                    {({ values, setFieldValue, errors }) => (
                      <Form>
                        {/* doc title */}
                        <Box className="field" sx={{ marginBottom: "1rem" }}>
                          <Field name="title" type="input" label="Titre" id="title" as={TextField} fullWidth size="small" />
                          <ErrorMessage name="title" component={MzErrorText} />
                        </Box>
                        {progress && (
                          <Stack margin="1rem 0rem" gap="0.5rem" direction="row" sx={{ alignItems: "center", justifyContent: "center" }}>
                            <Typography color={colors.main_purple}>{progress ? progress + "%" : null}</Typography>
                            <CircularProgress sx={{ color: `${colors.main_purple}` }} />
                          </Stack>
                        )}

                        {/* doc file */}
                        <Box display="flex" flexDirection="column" sx={{ marginBottom: "1rem" }}>
                          <Button variant="upload" sx={{ overflow: "hidden", height: "200px", display: "flex", flexDirection: "column" }} component="label">
                            <img src={FileIcon} width="50px" alt="" style={{ marginBottom: "0.5rem" }} />
                            <input
                              style={{ opacity: "0%", width: "100%", height: "100%", position: "absolute" }}
                              id="document"
                              name="document"
                              type="file"
                              onChange={(event) => {
                                const doc = event.target.files[0];
                                setFieldValue("document", event.target.files[0]);
                                setDocToAddName(doc.name);
                              }}
                            />
                            <Typography sx={{ fontSize: "1rem", fontWeight: "400" }}>Déposer votre fichier</Typography>
                          </Button>
                          <MzErrorText>{errors.document}</MzErrorText>
                        </Box>
                        {docToAddName && (
                          <Box>
                            <Typography sx={{ margin: "1rem 0rem", color: `${colors.main_purple}`, fontWeight: "500", textDecoration: "underline" }} fontSize="0.875rem">
                              {docToAddName}
                            </Typography>
                          </Box>
                        )}

                        {/* confirm add doc */}
                        <Box display="flex" flexDirection="row" gap="0.5rem">
                          <Button variant="primary" type="submit">
                            Ajouter
                          </Button>
                          <Button onClick={handleCloseAddDoc} variant="third">
                            Annuler
                          </Button>
                        </Box>
                        {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                      </Form>
                    )}
                  </Formik>
                  {/* end form add DOC */}
                </Box>
              </Modal>
              <ConfirmationModal title="Supprimer un document" message="Êtes-vous sûr de bien vouloir supprimer ce document ?" acceptLabel="oui" refuseLabel="non" open={openDeleteDoc} onClose={handleCloseDeleteDoc} acceptMethod={deleteDoc} acceptMethodParams={selectedDocToDelete?.id} />
            </Box>
          )}
        </Box>
        {/* End links docs section */}
      </Box>
    </Box>
  );
}

export default CarteAbonneDetails;
