import React from "react";
import { Box, Typography } from "@mui/material";
import IconFile from "../../imgs/file.svg";

function IconLink({ icon, title, value }) {
  return (
    <Box display="flex" flexDirection="row" gap="0.5rem" alignItems="center">
      <Box className="well" display="flex" sx={{ alignItems: "center", justifyContent: "center", backgroundColor: "white", padding: "12px", borderRadius: "18px", margin: "0.5rem 1rem" }}>
        {icon ? <img width="20px" height="20px" alt={`${title} icon`} src={`${icon}`} /> : <img width="20px" height="20px" alt={`${title} icon`} src={IconFile} />}
      </Box>

      <Box>
        <Typography>
          <a target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: "black" }} href={value}>
            {title}
          </a>
        </Typography>
      </Box>
    </Box>
  );
}

export default IconLink;
