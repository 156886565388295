import React from "react";
import { useState, useEffect, useRef } from "react";
import { Box, TextField, Button, Typography, Modal, Autocomplete, Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import { frFR } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import { columns } from "../../data/entreprisesData";
import { DataGridHeaderStyle } from "../../components/dataGridStyles/DataGridHeaderStyle";
import { CustomToolbar } from "../../components/dataGridStyles/CustomToolbar";
import EmptyDataGridOverlay from "../../components/ui/EmptyDataGridOverlay";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import * as Yup from "yup";
import MzErrorText from "../../components/ui/MzErrorText";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useNavigate } from "react-router-dom";
import { StripedDataGrid } from "../../components/ui/StripedGrid";
import { ToastContainer, toast } from "react-toastify";
import ConfirmationModal from "../../components/ui/ConfirmationModal";
import { ModalStyle } from "../../theme/ModalStyles";
import API from '../../api/Api_1_3'
import { Add, Apartment, Close, FilterList, Settings } from "@mui/icons-material";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

function Entreprises() {
  const { user } = useAuthContext();
  const access_token = user.access_token;
  //ondelete
  const [delSuc, setDelSuc] = useState(false);
  //navigate to create enterprise
  const navigate = useNavigate();
  const navigateCreateEntreprise = () => {
    navigate("/admin/gestion-des-entreprises/creer");
  };
  const navigateImportEntreprise = () => {
    navigate("/admin/gestion-des-entreprises/import");
  };

  const formRef = useRef();

  //data grid pages configuration
  const pageNumber = [25];
  const [plans,setPlans] = useState([])
  const [rowsAPI, setRowsAPI] = useState([]);
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(pageNumber[0]);
  //data grid pages configuration
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [role, setRole] = useState("");
  //initial values
  const initialValues = {
    id: "",
    socialReason: "",
    commercialName: "",
    commercialRegister: "",
    nif: "",
    countryName: "",
    cityName: "",
  };

  //validation schema
  const validationSchema = Yup.object({
    id: Yup.string(),
    socialReason: Yup.string(),
    commercialName: Yup.string(),
    commercialRegister: Yup.string(),
    nif: Yup.string(),
    cityName: Yup.string(),
    countryName: Yup.string(),
  });

  //page state
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  // eliminate empty data
  function nonEmptyObject(obj) {
    for (const propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "") {
          delete obj[propName];
      }
    }
    return obj;
}


  const getPlans = async () => {
    const res = await Api.get('/admin/plans',{
        headers: {
            authorization: `Bearer ${access_token}`,
        },
    })
    const data = await res.data.data
    const values = data.map((item) => ({ id: item.id, name: item.name }))
    setPlans(values);
  }

  const getEnterprises = ()=>{
    setIsLoading(true);
    //get user role
    Api.get(Urls.USERS_ROLE, {
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
      .then((res) => {
        //get companies
        API.get('/enterprises', {
          headers: {
            "Content-type": "application/json",
            accept: "application/json",
            authorization: `Bearer ${access_token}`,
          },
          params: { perPage: perPage },
        })
          .then((response) => {
            setPage(0);
            setIsLoading(false);

            if (res.data.data === "SALE") {
              const temp = response.data.data.map(
                (item) =>
                  (item = {
                    ...item,
                    social_reason: "##########",
                    nif: "############",
                    commercial_register: "############",
                  })
              );
              console.log(temp);
              setRowsAPI(temp);
            } else {
              setRowsAPI(response.data.data);
            }
            // console.log(rowsAPI);
            setTotal(response.data.meta.total);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              localStorage.removeItem("user");
              navigate("/login", { replace: true });
              navigate(0, { replace: true });
            }
            // console.log(error);
            if (error?.response?.status === 403) {
              toast.error("Une erreur est survenue");
            } else {
              toast.error(error?.response?.data?.message);
            }
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  //on page load
  useEffect(() => {
    getEnterprises()
    getPlans()
  }, []);

  //on search
  const onSubmitSearchUsers = (values) => {
    let realValues = nonEmptyObject(values);

    setPage(0);
    setIsLoading(true);
    API.get('/enterprises' + `?perPage=${perPage}`, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
      params: realValues,
    })
      .then((response) => {
        setIsLoading(false);
        setRowsAPI(response.data.data);
        setTotal(response.data.meta.total);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // console.log(error);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };

  const resetFilter = () => {

    setPage(0);
    setIsLoading(true);
    API.get('/enterprises' + `?perPage=${perPage}`, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
      .then((response) => {
        setIsLoading(false);
        setRowsAPI(response.data.data);
        setTotal(response.data.meta.total);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // console.log(error);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };
  //supp une entreprise
  const [ondelite, setOnDelete] = useState(false);
  const [ondeliteNext, setOnDeletNext] = useState(false);
  const [motdepasse, setMotDePasse] = useState("0zFgtYmLv9");
  //delete entreprise
  const deleteENT = () => {
    Api.delete(Urls.DELETE_ENTREPRISE, {
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${access_token}`,
      },
      data: {
        enterprise_ids: selectedIDs,
        passphrase: motdepasse,
      },
    })
      .then((res) => {
        toast.success("entreprise supprimée");
        setOnDeletNext(false);
        setDelSuc(!delSuc);
        getEnterprises()
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <Box>
      {/* Header Section */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
  <Button
    variant="primary"
    onClick={handleClick}
    sx={{ display: 'flex', alignItems: 'center', gap: '.5rem', marginBottom: "1rem" }}
  >
    <Apartment />
    Nouvelle entreprise
  </Button>
  <Menu
    anchorEl={anchorEl}
    open={Boolean(anchorEl)}
    onClose={handleClose}
  >
    <MenuItem onClick={navigateCreateEntreprise}>
      <ListItemIcon>
        <Add />
      </ListItemIcon>
      <ListItemText primary="Manual entreprise" />
    </MenuItem>
    <MenuItem onClick={navigateImportEntreprise}>
      <ListItemIcon>
        <AutoFixHighIcon />
      </ListItemIcon>
      <ListItemText primary="Auto Enterprise" />
    </MenuItem>
  </Menu>
</Box>

      <ToastContainer
        style={{ top: "5rem" }}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      {/* Filter  Section */}
      {/* Form Section */}
      <Box className="filter" display="flex" flexDirection="row">
        <Formik
          enableReinitialize={true}
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            onSubmitSearchUsers(values, access_token);
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Box display="flex" flexDirection="column">
                <Box>
                  <Typography noWrap mr marginTop="0.45rem" mb>
                    Filtre
                  </Typography>
                </Box>

                {/*Search fields  */}

                <Box
                  display="flex"
                  maxWidth="900px"
                  sx={{ flexDirection: { md: "row", xs: "column" } }}
                  marginBottom="1rem"
                >
                  <Box flex={1} alignItems="center" marginRight="1rem" mb>
                    <Field
                      label="Nom commercial"
                      name="commercialName"
                      as={TextField}
                      type="input"
                      size="small"
                    />
                    <ErrorMessage
                      name="commercialName"
                      component={MzErrorText}
                    />
                  </Box>
                  <Box flex={1} alignItems="center" marginRight="1rem" mb>
                  <Field
                      name="type"
                      component={Autocomplete}
                      options={['CLIENT','GIFT']}
                      isOptionEqualToValue={(option, value) => option === value}
                      getOptionLabel={(s) => s}
                      onChange={(event, value) => {
                        setFieldValue('type',value);
                      }}
                      renderInput={(params) => <TextField {...params} name="autocomplete" label="Type" sx={{width:200}} />}
                      size="small"
                    />{" "}
                    <ErrorMessage name="Type" component={MzErrorText} />
                  </Box>

                  <Box flex={1} alignItems="center" marginRight="1rem" mb>
                  <Field
                      name="type"
                      component={Autocomplete}
                      options={['WAITING','CONFIRMED','REJECTED']}
                      isOptionEqualToValue={(option, value) => option === value}
                      getOptionLabel={(s) => s}
                      onChange={(event, value) => {
                        setFieldValue('status',value);
                      }}
                      renderInput={(params) => <TextField {...params} name="autocomplete" label="Status" sx={{width:200}} />}
                      size="small"
                    />{" "}
                    <ErrorMessage name="Status" component={MzErrorText} />
                  </Box>

                </Box>

                <Box
                  display="flex"
                  maxWidth="600px"
                  sx={{ flexDirection: { md: "row", xs: "column" } }}
                >
                  <Box flex={1} alignItems="center" marginRight="1rem" mb>
                  <Field
                      sx={{ maxWidth: "350px" }}
                      name="plan_id"
                      component={Autocomplete}
                      options={plans}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      onChange={(event, value) => {
                        setFieldValue("plan_id", value ? value.id : "");
                      }}
                      renderInput={(params) => (
                        <TextField
                          sx={{ maxWidth: "350px" }}
                          {...params}
                          name="autocomplete"
                          label="Plan"
                        />
                      )}
                      size="small"
                    />

                    <ErrorMessage name="plans" component={MzErrorText} />
                  </Box>

                  <Box flex={1} display={'flex'} gap={1} alignItems={'center'} marginRight="1rem" mb>
                    <Button type="submit" variant="primary">
                      <FilterList/>
                    </Button>

                    <Button onClick={()=>resetFilter()} variant="primary">
                      <Close/>
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>{" "}
      <Box>
        <Button
          onClick={() => setOnDelete(true)}
          disabled={selectedIDs.length === 0 ? true : false}
          sx={{
            ":disabled": {
              backgroundColor: "gray",
              color: "white",
              border: "2px solid gray",
            },
          }}
          variant="third"
        >
          Supprimer une/des entreprise(s)
        </Button>
      </Box>
      <ConfirmationModal
        title="Supprimer une/des entreprise(s)"
        message="Êtes-vous sûr de bien vouloir supprimer cette/ces entreprise(s) ?"
        acceptLabel="oui"
        refuseLabel="non"
        open={ondelite}
        onClose={() => setOnDelete(false)}
        acceptMethod={() => setOnDeletNext(true)}
      />
      <Modal open={ondeliteNext} onClose={() => setOnDeletNext(false)}>
        <Box sx={ModalStyle}>
          <Typography fontSize="1.5rem" fontWeight="600" mb>
            Supprimer une/des entreprise(s){" "}
          </Typography>
          <Typography mb="1rem">Entrez votre mot de passe</Typography>
          <Box marginBottom="1rem">
            <TextField
              type="password"
              value={motdepasse}
              onChange={(e) => setMotDePasse(e.target.value)}
              name="motdepasse"
              placeholder="Mot de passe"
            />
          </Box>
          <Box display="flex" flexDirection="row" gap="0.5rem">
            <Button
              variant="primary"
              onClick={() => {
                deleteENT();
              }}
            >
              Supprimer
            </Button>
            <Button onClick={() => setOnDeletNext(false)} variant="third">
              Annuler
            </Button>
          </Box>
        </Box>
      </Modal>

      <div>{role}</div>
      <Box sx={DataGridHeaderStyle}>
        <StripedDataGrid
          //stripes
          //stripes
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "odd" : "even"
          }
          sx={{ maxWidth: "100%" }}
          rowHeight={40}
          //allow for multiple rows selection when checkboxSelection is on
          onSelectionModelChange={(ids) => {
            const selection = new Set(ids);
            setSelectedIDs(ids);
            const selectedRows = rowsAPI.filter((row) => selection.has(row.id));
            setSelectedRows(selectedRows);
          }}
          loading={isLoading}
          disableSelectionOnClick
          components={{
            Toolbar: CustomToolbar,
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: EmptyDataGridOverlay,
          }}
          columns={columns}
          //data to feed datagrid
          rows={rowsAPI}
          //allow selection
          checkboxSelection
          //how many rows in a page ?
          onPageSizeChange={(newPageSize) => {
            setPerPage(newPageSize);
            // console.log(newPageSize);
          }}
          pageSize={perPage}
          rowsPerPageOptions={pageNumber}
          //mode
          paginationMode="server"
          //total number of rows per search
          rowCount={total}
          //onpage change
          onPageChange={(newPage) => {
            // console.log(newPage);
            setPage(newPage);
          }}
          pagination
          page={page}
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        />
      </Box>
    </Box>
  );
}

export default Entreprises;
