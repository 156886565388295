import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Outlet, useNavigate } from "react-router-dom";
import IconBdigitalWhite from "../../imgs/BDIGITALL_WHITE.svg";
import MenuIcon from "@mui/icons-material/Menu";
import DefaultProfilePic from "../../imgs/user.png";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { useAuthContext } from "../../hooks/useAuthContext";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconCommande from "../../imgs/commande.svg";
import IconApresVente from "../../imgs/apresVente.svg";
import IconEntreprise from "../../imgs/entreprise.svg";
import IconCarte from "../../imgs/carte.svg";
import { NavLink } from "react-router-dom";
import contacts from "../../imgs/Contacts.svg";
import page from "../../imgs/page.svg";
import plan from '../../imgs/plan.svg'
import group from '../../imgs/group.svg'
import gift from '../../imgs/gift.svg'
import { Button, Collapse } from "@mui/material";
import { ExpandLess, ExpandMore, InfoOutlined, Notifications } from "@mui/icons-material";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import Cookies from 'js-cookie';
import API from "../../api/Api_1_3";
import { ToastContainer, toast } from "react-toastify";
import { app } from "../../firebase";
import LensIcon from '@mui/icons-material/Lens';


const drawerWidth = 300;
const navigation = [
  { title: "Gestion des entreprises", path: "/admin/gestion-des-entreprises" },
  { title: "Gestion des BCARDs", path: "/admin/gestion-des-cartes" },
  { title: "Gestion des commandes", path: "/admin/gestion-des-commandes" },
  { title: "Service après vente", path: "/admin/service-apres-vente" },
  { title: "Bgift", path: "/admin/service-bgift" },
  { title: "Plans", path: "/admin/plans" },
  { title: "BDisplay", 
    children:[
      { title: "Gestion des BDisplays", path: "/admin/pages" },
      { title: "Gestion des Groupes", path: "/admin/pages/groups" },
    ]
  },
  // { title: "Contacts", path: "/admin/contacts" },
  // { title: "Ajouter collaborateurs", path: "/admin/create-users-hiddenroute" },
];

const icons = {};
icons["Gestion des entreprises"] = IconEntreprise;
icons["Gestion des BCARDs"] = IconCarte;
icons["Gestion des commandes"] = IconCommande;
icons["Service après vente"] = IconApresVente;
icons["Bgift"] = gift ;//change icon
icons["Plans"] = plan ;//change icon
icons["Contacts"] = contacts;
icons["BDisplay"] = page;
icons["Gestion des BDisplays"] = page;
icons["Gestion des Groupes"] = group;


function SharedLayoutAdmin(props) {

  const { user } = useAuthContext();
  const access_token = user.access_token;
  const messaging = getMessaging(app);
  const [notifications,setNotifications] = React.useState([])
  const [unread,setUnread] = React.useState([])
  const fcm_token = Cookies.get('fcm')

  const updateFcmToken = () =>{
    getToken(messaging,
     {vapidKey:'BPqGYSMiP2gllU5lEQ3aLYchXlX0WibmfULRXKzp_dROWj9TnNLusm03bIJQj_AazVgAeqJTIRcxzxK6l3WYf7k'})
     .then(async(currentToken) => {
     if(!fcm_token){
         Cookies.set('fcm',currentToken)
     }
     await API.patch(
       '/user/update-fcm-token',
       {
         fcm_token: currentToken
       },
       {
         headers: {
           "Content-Type": "application/json",
           "Authorization": `Bearer ${access_token}`
         }
       }
     );
   }).catch((err) => {
     console.log('An error occurred while retrieving token. ', err);
  // ...
 });
}

  const CustomNotification = ({ title, body }) => (
    <div>
      <h3 style={{'fontSize':'1.1rem',marginBottom:'.5rem',fontWeight:600}}>{title}</h3>
      <p style={{fontWeight:300}}>{body}</p>
    </div>
  );

  onMessage(messaging,(payload) => {
    toast.info(<CustomNotification title={payload.notification.title} body={payload.notification.body} />);
    getNotifs()
  });


  const imageSrc = null;

  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const navigate = useNavigate();

  const handleLogout = () => {
    setAnchorElUser(null);

    Api.post(Urls.LOGOUT, null, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((response) => {
        // console.log("Response Logout", response);
        if (response.status === 200) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // console.log(error.response.data.message);
      });
  };
  
  const [open,setOpen] = React.useState(false)

  const handleClick = ()=> {
    setOpen(!open)
  }

  const getNotifs = async ()=>{
    const res = await API.get('/user/notifications',{
      headers:{
        "Content-Type": "application/json",
        "Authorization": `Bearer ${access_token}`
      }
    })
    const data = res.data?.data
    setNotifications(data)
    setUnread(data.filter(ntf => ntf.read_at == null))
  }

  const markAsRead = async () =>{
    const res = await API.get('/user/notifications/mark-as-read',{
      headers:{
        "Content-Type": "application/json",
        "Authorization": `Bearer ${access_token}`
      }
    })
    const message = await res.data.message
    
    await getNotifs()
    handleClose()
    toast.success(message)
    
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  
  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(()=>{
    getNotifs()
    updateFcmToken()
  },[])

  const drawer = (
    <Box>
      <Toolbar sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
        <Box>
          <Box sx={{ padding: "2.5rem 0rem" }}>
            <Box>
              <img src={IconBdigitalWhite} width={250} alt="moonz logo" />
            </Box>
          </Box>
        </Box>
      </Toolbar>

      <List>
        {navigation.map((l, index) => {
          if(l.title === 'BDisplay'){
           return(
            <React.Fragment key={index}>
            <ListItem button onClick={handleClick} sx={{'paddingY':'.7rem'}}>
            <ListItemIcon>
              <img
                style={{ width: "20px", marginLeft: "20px" }}
                alt="icon"
                src={icons["BDisplay"]}
              />
            </ListItemIcon>
            <ListItemText primary="BDisplay" sx={{ color: "white" }} />
            {open ? <ExpandLess sx={{ color: "white" }} /> : <ExpandMore sx={{ color: "white" }} />}
            </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {l.children.map((child, childIndex) => (
                <ListItem key={childIndex} button sx={{ pl: 4 }}>
                  <NavLink
                    id="navlink"
                    className={({ isActive }) =>
                      isActive ? "active" : "inactive"
                    }
                    to={child.path}
                  >
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <ListItemIcon>
                        <img
                          style={{ width: "20px", marginLeft: "20px" }}
                          alt="icon"
                          src={icons[child.title]}
                        />
                      </ListItemIcon>
                      <ListItemText
                        sx={{ color: "white" }}
                        primary={child.title}
                      />
                    </Box>
                  </NavLink>
                </ListItem>
              ))}
            </List>
          </Collapse>
        </React.Fragment>
           )
          } else {
            return (
              <React.Fragment key={index}>
              <ListItem sx={{'paddingY':'.7rem'}} >
                <NavLink id="navlink" className={({ isActive }) => (isActive ? "active" : "inactive")} to={l.path}>
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <ListItemIcon>
                      <img style={{ width: "20px", marginLeft: "20px" }} src={icons[`${l.title}`]} alt="profile_pic" />
                    </ListItemIcon>
                    <ListItemText sx={{ color: "white" }} primary={l.title} />
                  </Box>
                </NavLink>
              </ListItem>
              </React.Fragment>
            )
          }
})}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar sx={{ backgroundColor: `black`, color: "white", display: "flex", justifyContent: "space-between" }}>
          <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2, display: { sm: "none" } }}>
            <MenuIcon></MenuIcon>
          </IconButton>
          <Box></Box>
          <Box display="flex" gap="1.5rem" justifyContent="center" alignItems="center">
            <Button
              sx={{'cursor':'pointer',color:'white',position:'relative'}} 
              id="basic-button"
              aria-controls={ openMenu ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={ openMenu ? 'true' : undefined}
              onClick={handleClickMenu}
            >
              {unread.length !== 0 ?
              <Box>
                <LensIcon sx={{'color':'red', position:'absolute',width:'1.1rem',top:'0',right:'1rem'}}/> 
                <p className="absolute top-1 font-bold right-[1.3rem] text-xs">{unread.length === 0 ? null : unread.length}</p>
              </Box>
               : null}
              <Notifications/>
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              sx={{'width':'70%'}}
            >
              <MenuItem>
                <Box display={'flex'} width={'100%'} alignItems={'center'} justifyContent={'space-between'} >
                  <Typography variant="h6" color="initial">Notifications</Typography>
                  <Button disabled={notifications[0]?.read_at !== null} onClick={()=> markAsRead()} size='small'>Mark all as read</Button>
                </Box>
              </MenuItem>
              {notifications !== null ? notifications.map((ntf,index)=>(
                <MenuItem sx={{width:'100%','display':'flex',flexDirection:'row',padding:'1rem',alignItems:'center','opacity':ntf.read_at !== null ? 0.5 : 1,borderBottom:'.5px solid #232323',gap:'.5rem'}} key={index}>
                  <Box>
                    <InfoOutlined/>
                  </Box>
                  <Box maxWidth={'sm'} display={'flex'} flexDirection={'column'} alignItems={'start'}>
                    <Typography variant="p" sx={{'fontSize':'1rem', fontWeight:ntf.read_at !== null ? 600 : 700}} color="initial">{ntf.notification_data.title}</Typography>
                    <Typography variant="body1" color="initial" sx={{ whiteSpace: 'break-spaces', fontSize:'.9rem' }}>{ntf.notification_data.body}</Typography> 
                  </Box> 

                </MenuItem> 
              )) : 
              
                <MenuItem sx={{width:'100%','display':'flex',flexDirection:'row',padding:'1rem',alignItems:'center','opacity': 0.5,borderBottom:'.5px solid #232323',gap:'.5rem'}} >
                  <Box maxWidth={'sm'} display={'flex'} flexDirection={'column'} alignItems={'start'}>
                    <Typography variant="p" sx={{'fontSize':'1rem', fontWeight: 600 }} color="initial">New Notifications Will be Shown</Typography>
                  </Box> 
                </MenuItem>


              }

            </Menu>
            <Box textAlign="right">
              <Typography noWrap component="div">
                super Admin
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar sx={{ width: "3rem", height: "3rem" }}>{imageSrc === null ? <img style={{ width: "3rem" }} src={DefaultProfilePic} alt="" /> : <img style={{ width: "3rem" }} id="imgNavbar" src={imageSrc} alt="" />}</Avatar>
              </IconButton>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem>
                  <Typography onClick={handleLogout} textAlign="center">
                    Se déconnecter
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />

        <Box sx={{ padding: { md: "2rem", sm: "0.2rem" } }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}

SharedLayoutAdmin.propTypes = {
  window: PropTypes.func,
};

export default SharedLayoutAdmin;
