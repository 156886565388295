import { createTheme } from "@mui/material/styles";
import { colors } from "./Colors";

//MUI CUSTOM THEME
export const theme = createTheme({
  palette: {
    primary: {
      main: colors.bd_Purple,
      light: colors.light,
      dark: colors.dark,
      white: "#FFFFF",
      preselected: colors.mj_preselected,
      confirmed: colors.main_mj,
      refused: colors.mj_error,
      accepted: colors.mj_green,
      pending: colors.mj_pending,
    },
  },

  typography: {
    fontFamily: ["Poppins", "Inter"].join(","),
  },

  components: {
    //checkbox
    MuiCheckbox: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontSize: "2rem",
          "&:hover": {
            backgroundColor: "transparent",
          },
          "& path": {
            color: colors.gray,
          },
          color: colors.main_purple,
        },
      },
    },

    //drawer
    MuiDrawer: {
      styleOverrides: {
        paper: {
          background: `${colors.bd_Purple}`,
        },
      },
    },

    //navbar
    AppBar: {
      variants: [
        {
          props: {
            variant: "mj_navbar",
          },
          style: {
            background: `${colors.mj_navbar}`,
            color: "white",
          },
        },
      ],
    },

    //Input textfield
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
          backgroundColor: "white",
        },
      },
    },

    //Textfield
    MuiTextField: {
      styleOverrides: {
        root: {
          color: colors.tFieldGray,
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: colors.tFieldGray,
            },
            "&:hover fieldset": {
              border: `3px solid #E7D3FF`,
            },
            "&:focus fieldset": {
              border: `2px solid ${colors.bd_Purple}`,
            },
            "&.Mui-focused fieldset": {
              border: `2px solid ${colors.bd_Purple}`,
            },

            "& .MuiInputBase-input ": {
              padding: "10px 10px",
            },
          },
        },
      },
    },

    //TEXT
    MuiTypography: {
      variants: [
        {
          props: {
            variant: "secondary",
          },
          style: {
            color: `${colors.bd_text_secondary}`,
          },
        },
        {
          props: {
            variant: "detail_orange_large",
          },
          style: {
            color: `${colors.main_mj}`,
            fontWeight: "600",
            fontSize: "1.5rem",
          },
        },
        {
          props: {
            variant: "small",
          },
          style: {
            fontSize: ".875rem",
          },
        },
        {
          props: {
            variant: "small_purple",
          },
          style: {
            fontSize: ".875rem",
            color: `${colors.main_purple}`,
          },
        },
        {
          props: {
            variant: "small_grey_underline",
          },
          style: {
            fontSize: ".875rem",
            color: `${colors.gray}`,
            textDecoration: "underline",
            fontWeight: "500",
          },
        },
      ],
    },

    MuiButton: {
      //BUTTONS VARIANTS
      variants: [
        //MJ_BUTTON_PRIMARY
        {
          props: { variant: "primary" },
          style: {
            fontWeight: "300",

            padding: "5px 12px",
            transition: "ease all 0.5s",
            backgroundColor: colors.main_purple,
            borderRadius: "2rem",
            color: "white",
            fontSize: "0.875rem",
            border: `2px solid ${colors.main_purple}`,
            "&:hover": {
              backgroundColor: colors.main_purple_hover,
              color: "white",
              border: `2px solid ${colors.main_purple_hover}`,
            },
            textTransform: "none",
          },
        },
        //fourth
        {
          props: { variant: "upload" },
          style: {
            cursor: "pointer",
            fontWeight: "600",
            padding: "5px 12px",
            transition: "ease all 0.5s",
            backgroundColor: colors.main_purple_hover_light,
            borderRadius: "2rem",
            color: colors.main_purple,
            fontSize: "0.875rem",
            border: `3px  dashed  ${colors.main_purple}`,
            "&:hover": {
              backgroundColor: colors.main_purple_hover_light_hover,
              color: colors.main_purple,
              border: `3px  dashed ${colors.main_purple}`,
            },
            textTransform: "none",
          },
        },
        //third
        {
          props: { variant: "third" },
          style: {
            fontWeight: "300",

            padding: "5px 12px",
            transition: "ease all 0.5s",
            backgroundColor: colors.bd_suspended,
            borderRadius: "2rem",
            color: "white",
            fontSize: "0.875rem",
            border: `2px solid ${colors.bd_suspended}`,
            "&:hover": {
              backgroundColor: colors.bd_suspended_hover,
              color: "white",
              border: `2px solid ${colors.bd_suspended_hover}`,
            },
            textTransform: "none",
          },
        },
        //MJ BUTTON SECONDARY
        {
          props: { variant: "secondary" },
          style: {
            fontWeight: "500",

            padding: "5px 12px",
            transition: "ease all 0.5s",
            backgroundColor: "white",
            borderRadius: "2rem",
            color: colors.main_purple,
            fontSize: "0.875rem",
            border: `2px solid ${colors.main_purple}`,

            "&:hover": {
              backgroundColor: colors.main_purple_hover_light,
              color: colors.main_purple,
            },
            textTransform: "none",
          },
        },
         //fifth
         {
          props: { variant: "fifth" },
          style: {
            fontWeight: "300",

            padding: "5px 12px",
            transition: "ease all 0.5s",
            backgroundColor: colors.mj_green_light,
            borderRadius: "2rem",
            color: "white",
            fontSize: "0.875rem",
            border: `2px solid ${colors.mj_green_light}`,
            "&:hover": {
              backgroundColor: colors.mjGreenOutline,
              color: "white",
              border: `2px solid ${colors.mjGreenOutline}`,
            },
            textTransform: "none",
          },
        }
      ],
    },
  },
});
